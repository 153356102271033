<template>
  <div class="animated fadeIn container">
    <h1>1:1 문의</h1>
    <ul class="post-list mt-4" v-show="items.length > 0">
      <li v-for="item in items" :key="item.id">
        <div class="row">
          <div>
            <p class="color-skyblue">{{ item.type }}</p>
            <p class="date color-flat-white">{{ item.createdAt | dateFormatWithNewLine }}</p>
            <p class="mt-2" v-if="userLevel == 9">{{ item.user ? item.user.username : '' }}</p>
            <p v-if="userLevel == 9">{{ item.user ? item.user.email : '' }}</p>
          </div>
          <div class="title">{{ item.title }}</div>
          <div>
            <b-button @click.prevent="deletePost(item.id)" variant="primary">삭제</b-button>
          </div>
        </div>
        <div class="answer" v-if="userLevel < 9">
          <div class="profile"></div>
          <div class="body">
            {{ item.body || '답변 等待중입니다. 조금만 기다려주세요 ^^' }}
          </div>
        </div>
        <div class="answer-edit" v-if="userLevel == 9">
          <textarea v-model="item.body"></textarea>
          <div>
            <b-button @click.prevent="updatePost(item)" variant="warning">답변</b-button>
          </div>
        </div>
      </li>
    </ul>
    <b-pagination
      v-show="totalCount > 10"
      class="mt-4"
      align="center"
      :total-rows="totalCount"
      v-model="currentPage"
      :per-page="10"
      :limit="10"
      @change="changePage"
    ></b-pagination>
    <b-input-group class="mt-4">
      <div style="max-width: 150px">
        <b-form-select
          :plain="true"
          :options="postTypes"
          v-model="postType"
          value="문의유형"
          style="width: auto"
        ></b-form-select>
      </div>
      <textarea
        rows="4"
        v-model="title"
        placeholder="문의할 내용을 작성해주세요"
        required
        style="flex-grow: 1; margin-left: 20px; border: 1px solid #ededed; padding: 10px"
      ></textarea>
    </b-input-group>
    <div class="text-center mt-4">
      <b-btn
        ref="sendBtn"
        variant="primary"
        class="btn-primary send-btn mt-4"
        @click="createPost"
        :disabled="isLoading"
      >
        <span>1:1 문의하기</span>
      </b-btn>
    </div>
  </div>
</template>
<script>
import PostService from '@/services/PostService'
import { mapGetters } from 'vuex'

export default {
  name: 'Board',
  data: () => {
    return {
      isLoading: true,
      postTypes: ['문의유형', '충전문의', '발송문의', '제휴문의', '기타문의'],
      postType: '문의유형',
      items: [],
      title: '',
      body: '',
      currentPage: 0,
      totalCount: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    userLevel() {
      if (!this.$store.state.user) {
        return 0
      }
      return this.$store.state.user.level
    },
  },
  created() {
    this.currentPage = parseInt(this.$route.query.page) || 1
    this.getPosts()
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
  },
  methods: {
    async getPosts() {
      this.selectedRequests = []
      this.isLoading = true
      const response = await PostService.list({ page: this.currentPage })
      this.isLoading = false

      if (response.status === 200 && response.data) {
        this.items = response.data.list
        this.totalCount = response.data.totalCount
      }
    },
    async createPost() {
      if (this.postType == '문의유형') {
        alert('문의유형을 선택해주세요')
        return
      }

      try {
        await PostService.create({
          type: this.postType.trim(),
          title: this.title.trim(),
        })
        this.title = ''
        alert('문의가 접수되었습니다')
        this.currentPage = 1
        await this.getPosts()
      } catch (e) {
        // Do nothing
      }
    },
    async updatePost(item) {
      try {
        await PostService.update(item.id, item.body)
        alert('업데이트 成功')
      } catch (e) {
        // Do nothing
      }
    },
    async deletePost(id) {
      try {
        await PostService.delete(id)
        await this.getPosts()
        alert('삭제되었습니다')
      } catch (e) {
        // Do nothing
      }
    },
    changePage(page) {
      var query = {
        page: page,
      }

      this.$router.push({
        path: '/board',
        query,
      })
    },
  },
  watch: {
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getPosts()
    },
    items: function () {},
    user() {},
    isLoading() {},
    userLevel() {},
  },
}
</script>
<style scoped lang="scss">
.post-list {
  border-top: 1px solid #5f5f5f;
  border-bottom: 1px solid #5f5f5f;
  margin: 0;
  padding: 0;

  p {
    margin: 0;
  }

  > li {
    width: 100%;

    .row {
      margin: 0;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-flow: row;

      > div:first-of-type {
        min-width: 150px;

        > * {
          max-width: 130px;
          word-break: break-all;
        }
      }

      > div:last-of-type {
        min-width: 80px;
        text-align: right;
      }
      .title {
        flex-grow: 1;
        white-space: pre-line;
      }
    }

    .title {
      flex-grow: 1;
      white-space: pre;
    }

    .date {
      margin-top: 10px;
      white-space: pre;
    }

    .answer {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;
      background: #fafafa;
      display: flex;

      .profile {
        margin-left: 150px;
        display: inline-block;
        width: 56px;
        min-height: 56px;
        height: 100%;
        background: url('/img/icon_answer.png') center no-repeat;
        background-size: contain;
      }

      .body {
        padding: 10px 20px;
        white-space: pre;
      }
    }

    .answer-edit {
      padding: 20px 20px 20px 170px;
      display: flex;

      > textarea {
        border: 1px solid #ededed;
        flex-grow: 1;
        margin-right: 20px;
        min-height: 80px;
      }
    }
  }
}
</style>
